import React from "react"
import { graphql, Link } from "gatsby"

import SEO from "../components/seo"

class NotFoundPage extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <>
        <SEO title="404" />
        <h1 className="text-center pt-5">Запрашиваемая страница не найдена</h1>

        <Link to="/" className="d-block text-center pt-5">
          <u>Вернуться на главную</u>
        </Link>
      </>
    )
  }
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
